<template>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-3">
            <div class="p-5 bg-white rounded-lg shadow-lg">
                <ul class="list-none">
                    <li
                        v-for="(item, index) in tabs"
                        :key="index"
                        class="py-3 text-center cursor-pointer"
                        :class="
                            item.value[0] === choseTab[0]
                                ? 'bg-red-500 bg-opacity-20 rounded-lg'
                                : ''
                        "
                        @click="changeTab(item.value)"
                    >
                        {{ item.label }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-span-9">
            <SearchForm
                ref="searchFormRefDom"
                @onReset="resetData"
                @onSearch="filterSearch"
            />
            <div
                v-if="route.params.status == 0"
                v-permission="['update']"
                class="flex items-center justify-between p-5 my-3 bg-white rounded-lg action-bar"
            >
                <el-checkbox :value="selectAll" @change="choiceAll"
                    >全選此頁資料</el-checkbox
                >
                <div class="flex items-center">
                    <button
                        class="orange-btn-outline-800 md:text-sm text-xs h-[30px] mr-2"
                        @click.prevent="downloadTxt"
                    >
                        匯出成 TXT
                    </button>
                    <div
                        v-if="choiceDatas.length > 0"
                        class="text-[14px] text-gray-400 mr-[30px] font-normal"
                    >
                        已選擇 {{ choiceDatas.length }} 筆提領申請
                    </div>
                    <button
                        :disabled="choiceDatas.length == 0"
                        class="orange-btn-outline-800 md:text-sm text-xs h-[30px]"
                        @click="moveToFinshed"
                    >
                        移至撥款完成
                    </button>
                </div>
            </div>

            <div
                v-loading="loading"
                :class="route.params.status !== 0 ? 'mt-5' : ''"
                class="overflow-x-auto bg-white rounded-lg shadow-lg"
            >
                <div class="overflow-x-auto">
                    <table>
                        <TableHead
                            ref="tableHeadRefDom"
                            :customClass="'p-3 border-b border-gray-100'"
                            :labels="tableLabels"
                            @changeSort="changeSort"
                        />
                        <tbody>
                            <tr
                                v-for="(item, index) in datas"
                                :key="index"
                                :class="[
                                    item.status === 2
                                        ? 'bg-red-500 bg-opacity-20'
                                        : index % 2 === 0
                                        ? 'bg-gray-50'
                                        : '',
                                ]"
                                class="text-center transition duration-200"
                            >
                                <td v-if="route.params.status == 0">
                                    <input
                                        v-model="choiceDatas"
                                        type="checkbox"
                                        :value="item.id"
                                    />
                                </td>
                                <td>
                                    {{
                                        item.promoter !== undefined
                                            ? $subString(
                                                  item.promoter.user.name,
                                                  10
                                              )
                                            : ""
                                    }}
                                </td>
                                <td>{{ item.commission | formatCurrency }}</td>
                                <td>{{ item.created_at | formatDateTime }}</td>
                                <td
                                    v-permission="['update']"
                                    class="cursor-pointer"
                                    @click="goToEdit(item.id)"
                                >
                                    <i class="far fa-edit"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="flex justify-center mt-3 mb-3">
                    <MyPagination
                        :pagination-data="paginationData"
                        :current-page="currentPage"
                        @onPageSizeChange="pageSizeChange"
                        @onCurrentPageChange="currentPageChange"
                    />
                </div>
            </div>
        </div>
        <!-- 匯出TXT dialog -->
        <PopupInputDate
            v-model="showDialog"
            :param="remittanceDateDialogParam"
            @confirm="exportTxt"
        />
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// 搜尋表單
import SearchForm from "./SearchForm.vue";
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// table Head
import TableHead from "@/components/table/TableHead.vue";
// 判斷是 empty 方法
import { isEmpty } from "@/service/anyService";
// 匯出資料時 選擇撥款日期套件
import PopupInputDate from "@/components/popup/PopupInputDate.vue";
import fileDownload from "js-file-download";
// import { nextTick } from "vue/types/umd";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const route = useRoute();
  // 取得全域 this 方法 ex: this.$api, this.$message
  const {
    proxy
  } = getCurrentInstance();

  // table head dom
  const tableHeadRefDom = ref(null);
  // 預設搜尋條件
  const defaultFilterOpts = {
    statuses: [parseInt(route.params.status)]
  };
  // 搜尋條件值
  const filterOpts = ref(defaultFilterOpts);
  // 整理搜尋條件
  const filterData = computed(() => {
    const filter = {};
    for (const key in filterOpts.value) {
      // 判斷 key 是否有值
      if (!isEmpty(filterOpts.value[key])) {
        filter[key] = filterOpts.value[key];
      }
    }
    filter.statuses = [parseInt(route.params.status)];
    const result = {
      ...filter,
      page: currentPage.value,
      limit: paginationData.value.limit
    };
    return result;
  });
  // table head 標題
  const tableLabels = computed(() => {
    if (route.params.status == 0) {
      return [{
        label: "#"
      }, {
        label: "提領人 暱稱"
      }, {
        label: "金額"
      }, {
        label: "申請時間"
      }, {
        label: "查看"
      }];
    }
    return [{
      label: "提領人 暱稱"
    }, {
      label: "金額"
    }, {
      label: "申請時間"
    }, {
      label: "查看"
    }];
  });
  // 搜尋表單 dom
  const loading = ref(false);
  // 搜尋表單 dom
  const searchFormRefDom = ref(null);
  // 分頁資料
  const paginationData = ref({
    limit: 10,
    total: 0
  });
  // 預設頁面
  const currentPage = ref(1);
  // 列表
  const datas = ref([{
    user: {
      name: ""
    },
    category: {
      name: ""
    }
  }]);
  // 選擇 tab
  const choseTab = ref([parseInt(route.params.status)]);
  // 審核狀態列表
  const tabs = ref([{
    label: "代付款項 撥款處理",
    value: [0, 1, 2]
  }, {
    label: "撥款異常",
    value: [-1]
  }, {
    label: "撥款完成記錄",
    value: [3]
  }]);
  /**
   * 選擇 tab 事件
   * @param { type Number(數字) } val 驗證狀態
   */
  function changeTab(val) {
    if (choseTab.value !== val) {
      router.push({
        name: "salary_withdraw_salesman",
        params: {
          status: val[0]
        }
      });
    }
    choseTab.value = val;
    filterOpts.value = {
      statuses: val
    };
    searchFormRefDom.value.resetData();
  }

  /**
   * 更換每頁筆數
   * @param { type Number(數字) } val 每頁筆數
   */
  function pageSizeChange(val) {
    // 更新每頁取得幾筆資料參數
    paginationData.value.limit = val;
    // 當前頁面設定為第一頁
    currentPage.value = 1;
    getList();
  }

  /**
   * 分頁切換
   * @param { type Number(數字) } val 下一頁數字
   */
  function currentPageChange(val) {
    // 設定目前分頁為第幾面
    currentPage.value = val;
    getList();
  }

  /**
   * 取得列表資料
   */
  async function getList() {
    if (route.params.status == 0) {
      filterData.value.statuses = [route.params.status, 1, 2];
    } else {
      filterData.value.statuses = [route.params.status];
    }
    loading.value = true;
    try {
      const {
        data
      } = await proxy.$api.GetSalesmanSalaryWithdrawRecordListApi(filterData.value);
      datas.value = data.data;
      currentPage.value = data.current_page;
      paginationData.value = {
        limit: data.per_page,
        total: data.total
      };
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "取得資料失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  /**
   * 跳轉編輯頁
   */
  function goToEdit(id) {
    router.push({
      name: "salary_withdraw_salesman_detail",
      params: {
        id
      }
    });
  }

  // 點擊重置按鈕事件
  async function resetData() {
    filterOpts.value = defaultFilterOpts;
    currentPage.value = 1;
    tableHeadRefDom.value.reset();
    paginationData.value = {
      limit: 10,
      total: 0
    };
    await getList();
  }
  // 點擊搜尋按鈕事件
  async function filterSearch(val) {
    filterOpts.value = val;
    currentPage.value = 1;
    await getList();
  }
  // 更換排序
  const changeSort = async val => {
    filterOpts.value.sort_by = val.sortBy;
    filterOpts.value.sort_type = val.orderBy;
    await getList();
  };
  const showDialog = ref(false);
  // 下載 txt
  function downloadTxt() {
    showDialog.value = true;
  }

  /**
   * 盼度是否全選狀態
   */
  const selectAll = computed(() => {
    if (choiceDatas.value.length === 0) {
      return false;
    }
    const dataIds = datas.value.map(item => item.id);
    // 過濾當頁資料是否有被選中 且選中數量等於當夜數量 代表全選狀態
    return dataIds.filter(item => choiceDatas.value.includes(item)).length > 0 && dataIds.filter(item => choiceDatas.value.includes(item)).length === dataIds.length ? true : false;
  });
  // 選中資料
  const choiceDatas = ref([]);
  // 預計撥款日期選擇彈窗資料
  const remittanceDateDialogParam = ref({
    title: "匯出成 TXT",
    message: "請輸入預計匯款日期"
  });
  /**
   * 全選資料
   */
  function choiceAll() {
    const dataIds = datas.value.map(item => item.id);
    // 判斷是否為全選狀態
    if (selectAll.value) {
      dataIds.forEach(item => {
        choiceDatas.value.forEach((item2, index2) => {
          if (item == item2) {
            // 移除當前頁面選擇的 id
            choiceDatas.value.splice(index2, 1);
          }
        });
      });
    } else {
      dataIds.forEach(item => {
        if (!choiceDatas.value.includes(item)) {
          choiceDatas.value.push(item);
        }
      });
    }
  }

  /**
   * 移至撥款完成
   */
  function moveToFinshed() {
    const opts = {
      title: "移至撥款完成",
      message: "是否將所選資料移至撥款完成狀態"
    };
    proxy.$pop.popConfirm(opts).then(() => {
      //確認
      changeStatusSelects();
    }, () => {
      //取消
      console.log("cancel");
    });
  }

  /**
   * 更新狀態
   */
  async function changeStatusSelects() {
    const request = {
      ids: choiceDatas.value,
      status: 3
    };
    try {
      await proxy.$api.BulkUpdateSalemanSalaryWithdrawRecordApi(request);
      proxy.$message({
        type: "success",
        message: "更新成功"
      });
      getList();
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "更新失敗"
      });
    }
  }
  /**
   * 確認匯款資料是否有異常
   * @parma { type Date(日期格式) } 預計撥款日期
   */
  async function checkRemittances(date) {
    const request = {
      ids: choiceDatas.value,
      date: date
    };
    try {
      const {
        data
      } = await proxy.$api.CheckSalesmanWithdrawStatusApi(request);
      return {
        passed: [...data.passed],
        failed: [...data.failed]
      };
    } catch (err) {
      console.log(err);
      proxy.$message({
        type: "error",
        message: "匯出資料失敗"
      });
    }
  }

  /**
   * 匯出TXT
   * @parma { type Date(日期格式) } 預計撥款日期
   */
  async function exportTxt(date) {
    const {
      passed,
      failed
    } = await checkRemittances(date);
    if (passed.length === 0 && failed.length > 0) {
      alert(`有${failed.length}筆資料資訊異常，已移至異常處理`);
      getList();
      return;
    }
    try {
      const {
        data
      } = await proxy.$api.ExportSalesmanWithdrawDatasApi({
        ids: passed,
        date
      });
      fileDownload(data, `${proxy.$moment().format("YYYYMMDD")}.txt`);
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "匯出資料失敗"
      });
    } finally {
      choiceDatas.value = [];
    }
    if (failed.length > 0) {
      alert(`有${failed.length}筆資料資訊異常，已移至異常處理`);
      getList();
    }
  }
  watch(() => route.params.status, async (newV, oldV) => {
    if (newV !== undefined) {
      await getList();
      // 判斷不是代付款項頁面時 清空選擇的資料
      if (route.params.status !== 0) {
        choiceDatas.value = [];
        choseTab.value = [parseInt(newV)];
      } else {
        choseTab.value = [parseInt(newV), 1, 2];
      }
    }
  });
  onMounted(async () => {
    await getList();
  });
  return {
    route,
    tableHeadRefDom,
    tableLabels,
    loading,
    searchFormRefDom,
    paginationData,
    currentPage,
    datas,
    choseTab,
    tabs,
    changeTab,
    pageSizeChange,
    currentPageChange,
    goToEdit,
    resetData,
    filterSearch,
    changeSort,
    showDialog,
    downloadTxt,
    selectAll,
    choiceDatas,
    remittanceDateDialogParam,
    choiceAll,
    moveToFinshed,
    exportTxt
  };
};
__sfc_main.components = Object.assign({
  SearchForm,
  TableHead,
  MyPagination,
  PopupInputDate
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
table {
    @apply w-full;
}
th {
    @apply p-3 text-gray-400 font-normal min-w-[100px];
}
td {
    @apply p-3 border-b border-gray-200 min-w-[100px];
}
</style>
